<template>
  <div :class="{ 'sub-item': item.parentGroupId, 'sub-level-parent': !item.parentGroupId }">
    <div v-if="!item.links">
      <b-list-group-item @click="navigateToSubLink(item)">
        <b-icon v-if="item.sidebarIcon" class="mr-2 cursor" :icon="item.sidebarIcon"></b-icon>
        <b-icon-clipboard v-else class="mr-2"></b-icon-clipboard>
        {{ item.name }} </b-list-group-item>
    </div>
    <div v-else :style="{ paddingLeft: level * 20 + 'px' }">
      <b-list-group-item v-b-toggle="getToggleId(item)">
        <b-icon v-if="item.sidebarIcon" class="mr-2 cursor" :icon="item.sidebarIcon"></b-icon>
        <b-icon-clipboard v-else class="mr-2"></b-icon-clipboard>
        {{ item.name }}
      </b-list-group-item>
      <b-collapse :id="getToggleId(item)" accordion>
        <b-list-group>
          <!-- Recursive rendering of child items -->
          <template v-if="item.links">
            <MenuItem v-for="link in item.links" :key="link.name" :item="link" :level="level + 1" />
          </template>
          <!-- Recursive rendering of children -->
          <template v-if="item.children">
            <MenuItem v-for="child in item.children" :key="child.name" :item="child" :level="level + 1" />
          </template>
        </b-list-group>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import eventBus from '../eventBus';
export default {
  name: 'MenuItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    navigateToSubLink(item) {
      eventBus.$emit('on-click-link', item)
    },
    getToggleId(item) {
      return `toggle-${item.name.replace(/\s/g, '-').toLowerCase()}`;
    }
  }
};
</script>

<style>
/* Add indentation to the list items */
.list-group-item {
  padding-left: 30px;
}

/* Add more indentation for sub-items */
.sub-item .list-group-item {
  padding-left: 50px;
}
</style>

<template>
  <div class="home m-0">
    <sidebar />
    <navigator />
    <b-row class="mt-3" id="full-space">
      <b-col cols="12" v-for="(item, index) in messages" :key="index">
        <b-alert :show="dismissSecs" dismissible :variant="item.type" @dismiss-count-down="countDownChanged">{{
        item.message }}
        </b-alert>
      </b-col>
      <b-col class="p-0 m-0" cols="12">
        <router-view :key="$route.fullPath" />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import notificationService from '@/store/notificationService'
import breadcrumbService from '@/store/breadcrumbService'
import sidebar from '@/components/sidebar.vue'
import navigator from '@/components/navigator.vue'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    navigator,
    sidebar
  },
  data: () => ({
    dismissSecs: 15,
    dismissCountDown: 0,
    items: [
      {
        text: 'Admin',
        href: '#'
      },
      {
        text: 'Manage',
        href: '#'
      },
      {
        text: 'Library',
        active: true
      }
    ]
  }),
  beforeCreate() {
    // this.$store.commit('setRootData', this.$root.urlConfig)
    if (!this.$store.hasModule('notificationService')) {
      this.$store.registerModule('notificationService', notificationService);
    }

    if (!this.$store.hasModule('breadcrumbService')) {
      this.$store.registerModule('breadcrumbService', breadcrumbService);
    }
  },
  mounted() {
    this.$store.commit('setApiUrl', this.$root.urlConfig.api_url)
  },
  computed: {
    ...mapState('notificationService', ['messages']),
    ...mapState('breadcrumbService', ['breadcrumbs'])
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
      this.dismissCountDown = this.dismissSecs
    },
  },
}
</script>
<style>
#full-space {
  width: 100%;
  margin-top: 80px !important;
  height: 90vh !important;
}
</style>
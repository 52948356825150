<template>
  <div class="nav-bar" >
    <b-navbar class="pt-0 pb-0" type="dark" sticky style="width: 100% !important; position: fixed;">
      <b-navbar-brand>
        <h3 class="bold" style="color: #222222">LCS Gateway</h3>
      </b-navbar-brand>
      <b-navbar-nav class="ml-auto navbar-login-dropdown">
        <b-dropdown-item class="sign-out" v-b-toggle="'person_collapse'">
          <b-icon-person></b-icon-person>
        </b-dropdown-item>

        <b-collapse id="person_collapse" :v-model="user_view" left
          style="position: absolute; right:25px; border: black solid 1px;top: 55px;">
          <b-card title="User">
            <p>{{ userdata.name }}</p>
            <p>{{ userdata.surname }}</p>
            <p>{{ userdata.email }}</p>
            <p>{{ userdata.contactNumber }}</p>
            <p>{{ userdata.AssociatedEntity.Name }}</p>
            <p>{{ userdata.role.Name }}</p>
          </b-card>
        </b-collapse>

        <b-dropdown-item class="sign-out" @click="showModal()">
          <b-icon-power></b-icon-power>

        </b-dropdown-item>
      </b-navbar-nav>
    </b-navbar>

    <b-modal id="modal-1" hide-footer>
      <b-row>
        <b-col cols="12" class="text-center">
          <p>Are you sure you want to sign out?</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-center">
          <b-button @click="hideModal" class="mr-2 ml-2" variant="outline-red" squared>No</b-button>
          <b-button variant="primary" @click="logout" class="mr-2 ml-2" squared>Yes</b-button>
        </b-col>
      </b-row>
      <div class="d-block"></div>
    </b-modal>
  </div>
</template>
<style>
.primary {
  background-color: #ffcb14;
  color: #222222;
}

.primary:hover {
  color: #fff;
}

/* b-card {
  z-index: 100;
} */
</style>
<script>
export default {
  data: () => ({
    username: null,
    userdata: null,
    user_view: false
  }),
  created() {
    this.userdata = JSON.parse(localStorage.getItem("user_data"))
    this.username = localStorage.getItem('user')

  },
  methods: {
    changeLocation(newLocation) {
      this.$router.push({ path: newLocation })
    },
    showModal() {
      this.$root.$emit('bv::show::modal', 'modal-1', '#btnShow')
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-1', '#btnShow')
    },
    logout() {
      localStorage.clear();
      document.cookie = 'lcsgroup-_zldt' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie = 'lcsgroup-_zldp' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      this.$router.push({ path: '/' });

    }

  }
}
</script>